import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as LinkS, animateScroll as scroll } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
import tw from "twin.macro";
import "../../App.css";
import { Button, Container } from "..";
import { useRef } from "react";

const AppNav = styled.nav`
  background: ${({ scrollNavbar }) => (scrollNavbar ? "rgb(178 212 193)" : "rgb(178 212 193)")};
  backdrop-filter: blur(6px);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 104;
  @media screen and (max-width: 960px) {
    transition: 0.8 all ease;
  }
`;
const AppNavContainer = styled(Container)`
  ${tw`flex justify-between z-[1]`}
  ${Container}
`;
const AppNavLogo = styled.div`
  ${tw`flex items-center justify-self-start cursor-pointer`}
`;
const MobileIcon = styled.div`
  ${tw`hidden`}
  @media screen and (max-width: 960px) {
    ${tw`block absolute top-0 right-0 text-3xl cursor-pointer`}
    transform: translate(-100%, 60%);
  }
`;
const NavMenu = styled.ul`
  ${tw`flex items-center text-center`}
  list-style: none;
  @media screen and (max-width: 960px) {
    ${tw`flex  flex-col w-full absolute opacity-100 bg-lemon`}
    top: 80px;
    height: 100vh;
    left: ${({ click }) => (click ? 0 : "-100%")};
    transition: all 0.5s ease;
  }
`;
const NavItem = styled.li`
  ${tw`h-[80]`}
  border-bottom: 2px solid transparent;
  letter-spacing: 0.02em;
  font-size: 16px;
  &:hover {
    font-weight: 700;
    /* border-bottom: 2px solid green; */
    cursor: pointer;
  }
  @media screen and (max-width: 960px) {
    ${tw` w-full`}
    &:hover {
      border: none;
    }
  }
`;
const NavLinks = styled(LinkS)`
  ${tw`text-body flex justify-center items-center `}
  padding: 0.5rem 1rem;
  height: 100%;
  &.active {
    border-bottom: 2px solid green;
    @media screen and (max-width: 960px) {
      border-bottom: none;
      font-weight: 900;
    }
  }
  @media screen and (max-width: 960px) {
    ${tw`text-center table p-8 w-full`}
    &:hover {
      ${tw`font-bold cursor-pointer`}
      transition: all 0.3s ease;
    }
  }
`;
const NavItemLink = styled.li`
  ${tw`h-[80]`}
  font-weight: 700;
  &:hover {
    font-weight: 900;
    scale: 1.06;
    cursor: pointer;
  }
  @media screen and (max-width: 960px) {
    ${tw` w-full`}
    &:hover {
      border: none;
    }
  }
`;
const NavItemBtn = styled.li`
  ${tw` ml-10`}
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    margin-left: 0;
  }
`;
const NavBtnLink = styled(LinkR)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-block-end: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;
// const NavItemBtn = styled(LinkS)`

// `;

function Navbar() {
  const [scrollNavbar, setScrollNavbar] = useState(false);
  const [primary, setPrimary] = useState(true);
  const [white, setWhite] = useState(true);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const appNav = useRef();

  const ChangeBackground = () => {
    if (window.pageYOffset >= 80) {
      setScrollNavbar(true);
    } else {
      setScrollNavbar(false);
    }
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    // console.log(scrollNavbar, "i am here");

    window.addEventListener("scroll", ChangeBackground);
    // return () => {
    //   window.removeEventListener("scroll", ChangeBackground);
    // };
  }, [window]);

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);
    return () => {
      window.removeEventListener("resize", showButton);
    };
  }, []);

  return (
    <>
      {/* <Modal showModal={showModal} setShowModal={setShowModal} /> */}

      <IconContext.Provider value={{ color: "#3D4E4B" }}>
        <AppNav
          scrollNavbar={scrollNavbar}
          ref={appNav}
          onScroll={() => {
            // console.log("Hello");
          }}
        >
          <AppNavContainer>
            <AppNavLogo href="/" className="navbar-logo " onClick={toggleHome}>
              <img src="./assets/images/recordbeta-logo.svg" alt="recordbeta logo" />
            </AppNavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks
                  to="home"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  onClick={closeMobileMenu}
                >
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="works"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  onClick={closeMobileMenu}
                >
                  How It Works
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="why"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  onClick={closeMobileMenu}
                >
                  Why RecordBeta?
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="contact"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  onClick={closeMobileMenu}
                >
                  Contact Us
                </NavLinks>
              </NavItem>
              <NavItemLink>
                <NavLinks
                  to="products"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                  onClick={closeMobileMenu}
                >
                  Sign in
                </NavLinks>
              </NavItemLink>
              <NavItemBtn>
                {button ? (
                  <NavBtnLink to="/sign-up">
                    <Button primary={primary} white={white}>
                    Create an account
                    </Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink to="/sign-up">
                    <Button
                      primary={primary}
                      white={white}
                      onClick={closeMobileMenu}
                    >
                      Create an account
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
            </NavMenu>
          </AppNavContainer>
        </AppNav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
